.has-background-cremita {
    background-color: $cremita !important;
}

.has-background-morado {
    background-color: $morado !important;
}

.has-background-naranja {
    background-color: $naranja !important;
}

.has-background-space-black {
    background-color: $space-black !important;
}

.has-text-cremita {
    color: $cremita !important;
}

.has-text-morado {
    color: $morado !important;
}

.has-text-naranja {
    color: $naranja !important;
}

.has-text-space-black {
    color: $space-black !important;
}

.has-shadow {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
}

.list-item {
    background-color: white;
    margin-bottom: 5px;
    padding: 10px;
}

.list-item li span {
    font-size: 20px;
    font-weight: 600;
}

.has-rounded-border {
    border-radius: $radius;
}